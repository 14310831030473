import NetworkService from "@/services/NetworkService";
import store from "@/store";
import moment from "moment";

export default class UserService {
  constructor() {
    this.network = new NetworkService();
  }

  resetPassword(params) {
    return this.network.post(this.network.endpoints.passwordReset, params);
  }
  subscribe(params) {
    return this.network.post(this.network.endpoints.subscription, params);
  }
  cancelSubscription(id) {
    return this.network.delete(`${this.network.endpoints.subscription}/${id}`);
  }
  list = (filters) => {
    return this.network.get(this.network.endpoints.users, filters);
  };
  create = (params) => {
    return this.network.post(this.network.endpoints.users, params);
  };
  update = (id, params) => {
    return this.network.put(`${this.network.endpoints.users}/${id}`, params);
  };
  show = (id, params) => {
    return this.network.get(`${this.network.endpoints.users}/${id}`, params);
  };
  getUserData = async (id) => {
    const res = await this.show(id);
    let data = res.data;

    data.hasStripeSubs = data.stripe_subscription;

    const hasActiveStripeSubs =
      data.hasStripeSubs &&
      (data.stripe_subscription.status === "active" ||
        data.stripe_subscription.status === "trialing");

    data.subsType = data?.stripe_subscription?.plan?.type;
    data.subsTypeLabel = data?.stripe_subscription?.plan?.title;
    // data.subsType == "full" ? "Plano Full" : "Plano Básico";

    data.hasIAPSubs = data.plan && data.plan_id && data.subscription;

    const hasActiveIPASubs = data.hasIAPSubs && data.plans_status == "paid";

    data.isPremiumAccount = hasActiveIPASubs || hasActiveStripeSubs;

    data.hasSubs = data.hasIAPSubs || data.hasStripeSubs;

    data.hasSubsByAdmin =
      data.plan &&
      data.plan_id &&
      data.plan_status == "paid" &&
      !data.subscription;

    return data;
  };
  syncSession = async () => {
    let data = await this.getUserData(store.state.userSession?.id);
    store.dispatch("syncSessionWithApi", data);
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.users}/${id}`);
  };
  login = async (params) => {
    // console.log('trying to login')
    const res = await this.network.post(this.network.endpoints.login, params);
    if (res.data.type !== "admin") {
      // console.log('login res',res);
      await store.dispatch("saveToken", res.access_token);
      const data = await this.getUserData(res.data.id);
      return { ...res, data };
    } else {
      throw { status: false, message: "Credenciais inválidas" };
    }
  };
  forgotPassword = (params) => {
    return this.network.post(this.network.endpoints.forgotPassword, params);
  };
  logout = () => {
    return this.network.post(`${this.network.endpoints.users}/logout`);
  };
  settings = (key) => {
    return this.network.get(`${this.network.endpoints.settings}/${key}`);
  };
  updateSettings = (key, value) => {
    return this.network.put(`${this.network.endpoints.settings}/${key}`, {
      value,
    });
  };
  homeInfo = () => {
    return this.network.get(`${this.network.endpoints.homeInfo}`);
  };
  listCrashes = () => {
    return this.network.get(`${this.network.endpoints.appCrashes}`);
  };
  showCrash = (id) => {
    return this.network.get(`${this.network.endpoints.appCrashes}/${id}`);
  };
  planDetails = () => {
    return this.network.get(`${this.network.endpoints.planDetails}`);
  };
  getStripeStatus = (key) => {
    switch (key) {
      case "active":
      case "trialing":
      case "canceled_trialing":
      case "canceled_active":
        return "Assinante";
      case "incomplete":
        return "Incompleto";
      case "incomplete_expired":
        return "Expirado";
      case "past_due":
        return "Vencido";
      case "unpaid":
        return "Não pago";
      case "paused":
        return "Pausado";
      default:
        break;
    }
  };
  listNotifications = (params) => {
    return this.network.get(this.network.endpoints.notifications, params);
  };
  prepareMySubsInfo = (user) => {
    let isCancelable = true;
    let items = [
      {
        key: "Status",
        value: "Gratuito",
      },
    ];

    if (user.hasSubsByAdmin) {
      items[0].value = "Assinante";
    }

    if (user.hasSubs) {
      if (user.hasStripeSubs) {
        const stripe = user.stripe_subscription;
        const status = stripe?.status;
        const trialEndsAt = stripe?.trial_end
          ? moment(stripe?.trial_end).format("DD[ de ]MMMM")
          : null;
        const endsAt = stripe?.cancel_at
          ? moment(stripe?.cancel_at).format("DD[ de ]MMMM")
          : null;
        const daysLeft = stripe?.trial_end
          ? Math.round(moment(stripe?.trial_end).diff(moment(), "days", true))
          : null;

        if (endsAt) {
          isCancelable = false;
          items[0].endsAt = true;
          items[0].label = `Sua assinatura será encerrada em breve`;
          items[0].sublabel = `Você tem até o dia ${endsAt} para usufruir das funcionalidades como assinante.`;
        } else if (status == "trialing") {
          items[0].trial = true;
          items[0].label = `Você tem ${daysLeft} dias grátis para cancelar`;
          items[0].sublabel = `Você tem até o dia ${trialEndsAt} para cancelar gratuitamente seu plano.`;
        }

        items[0].value = this.getStripeStatus(status);

        items = [
          ...items,
          {
            key: "Data",
            value: moment(stripe?.created_at).format("DD[ de ]MMMM[ de ]YYYY"),
          },
          {
            key: "Valor",
            value: (stripe?.unit_amount / 100).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
          },
        ];
      } else if (user.hasIAPSubs) {
        items = [
          {
            key: "Status",
            value:
              user?.plan_status == "paid" ? "Assinante" : "Pagamento Pendente",
          },
          {
            key: "Data",
            value: moment(user?.subscription?.updated_at).format(
              `DD [de] MMMM [de] YYYY`
            ),
          },
          {
            key: "Valor",
            value: user?.plan?.price.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
          },
        ];
      }

      items.push(
        {
          key: "Período",
          value: "Mensal",
        },
        {
          key: "Termos",
          value: "Acessar termos",
          action: true,
        }
      );
    }

    return {
      isCancelable,
      items,
    };
  };
  validateEmail = (params) => {
    return this.network.post(this.network.endpoints.validateEmail, params);
  };
  validateCoupon = (params) => {
    return this.network.post(this.network.endpoints.validateCoupon, params);
  };
  getPlans = (params) => {
    return this.network.get(this.network.endpoints.plans, params);
  };
  showPlan = (id) => {
    return this.network.get(`${this.network.endpoints.plans}/${id}`);
  };
  preparePlan = (data) => {
    let plan = data;

    plan.monthly_price = plan.monthly_price.toCurrency(null, false);
    plan.annualy_price = plan.annualy_price.toCurrency(null, false);

    plan.benefits = plan.benefits.split(";").map((elem) => ({
      label: elem.split(":")[0],
      text: elem.split(":")[1],
    }));

    return plan;
  };

  loginWithBearerToken = async (query) => {
    try {
      if (!query.token || !query.user_id) {
        return true;
      }
      const token = query.token;
      const userId = query.user_id;
      // if (!store.state.userSession) {
      //NOTE: se der erro em alguma coisa, é pq essa linha servia para alguma coisa

      await store.dispatch("saveToken", token);
      const data = await this.getUserData(userId);
      if (data.type !== "admin") {
        await store.dispatch("saveSession", { data, access_token: token });
        return true;
      }
      return false;
      // }
    } catch (e) {
      return false;
    }
  };
}
