Number.prototype.toCurrency = function (amount, sign = true) {
  if (!amount) {
    amount = this;
  }
  let res = String(amount);
  try {
    res = `${sign ? "R$" : ""}${Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      .replace(/,/g, "*")
      .replace(/\./g, ",")
      .replace(/\*/g, ".")}`;
  } catch (e) {
    console.log(e);
  } finally {
    return res;
  }
};

Number.prototype.toLocaleCurrency = function (value) {
  if (!value) {
    value = this;
  }
  return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
};
