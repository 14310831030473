<template>
  <div id="app">
    <router-view />
    <NotificationToast />
    <ConfirmationAlert />
    <SubscriptionModal />
    <AboutAppModal />
  </div>
</template>

<script>
import * as pkg from "../package.json";
export default {
  name: "App",
  mounted() {
    console.log("VERSION:", `${pkg.version} - ${process.env.VUE_APP_ENV_NAME}`);
  },
  created() {
    document.title = "Bluu | Web App";
  },
};
</script>

<style>
body {
  background-color: #fff !important;
}
</style>
